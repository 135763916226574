

























































import { Component, Vue } from "vue-property-decorator";
import { Messages } from "@/models/enums/messages.enum";
import moment, { Moment } from "moment";
import {
  DATE_TIMESTAMP,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { accountingService } from "@/services/accounting.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { masterServices } from "@/services/master.service";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { useBlob, useDate } from "@/hooks";
import { SearchBuilder } from "@/builder";

type FormValues = {
  transactionType: string;
  periodFrom: Moment;
  periodTo: Moment;
};

@Component({
  mixins: [MNotificationVue],
})
export default class JournalTransactionsDetail extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form!: WrappedFormUtils;
  loading = {
    transactionType: false,
    download: false,
  };
  dtTransactionType = [] as ResponseListMaster[];
  formRules = {
    transactionType: {
      label: "lbl_transaction_type",
      name: "transactionType",
      placeholder: "lbl_transaction_type",
      decorator: [
        "transactionType",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    periodFrom: {
      label: "lbl_period_from",
      name: "lbl_period_from",
      placeholder: "lbl_period_from_placeholder",
      decorator: [
        "periodFrom",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    periodTo: {
      label: "lbl_period_to",
      name: "lbl_period_to",
      placeholder: "lbl_period_to_placeholder",
      decorator: [
        "periodTo",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };

  beforeCreate(): void {
    this.form = this.$form.createForm(this, {
      name: "journalTransactionsGroup",
    });
  }

  mounted(): void {
    this.getListTransactionType();
  }

  get formItemLayout() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
    };
  }

  getListTransactionType(): void {
    masterServices
      .listMaster({ name: "JOURNAL_TRANSACTION_TYPE" })
      .then(res => {
        this.dtTransactionType = res;
      });
  }

  generateParams(formValue: FormValues): string {
    const params: string[] = [
      this.$store.state.authStore.authData.companyName ||
        "PT. SATRIA PIRANTI PERKASA",
    ];

    params.push(formValue.transactionType || "ALL");

    if (formValue.periodFrom) {
      const from = moment(formValue.periodFrom).format(DEFAULT_DATE_FORMAT);
      params.push(from);
    } else {
      params.push("ALL");
    }

    if (formValue.periodTo) {
      const to = moment(formValue.periodTo).format(DEFAULT_DATE_FORMAT);
      params.push(to);
    } else {
      params.push("ALL");
    }

    return params.join(",");
  }

  handleDownload(): void {
    this.form.validateFields((err, value: FormValues) => {
      if (err && err.length) {
        this.showNotifError("lbl_validation_required_error");
        return;
      }
      const { periodTo, periodFrom, transactionType } = value;
      const { toStartDay, toEndDay } = useDate();
      const builder = new SearchBuilder();
      const searchQuery: string[] = [];
      const params = {} as RequestQueryParamsModel;
      params.params = this.generateParams(value);

      if (periodFrom) {
        searchQuery.push(
          builder
            .push(["journalDate", toStartDay(periodFrom).format()], {
              het: true,
            })
            .build()
        );
      }

      if (periodTo) {
        searchQuery.push(
          builder
            .push(["journalDate", toEndDay(periodTo).format()], {
              let: true,
            })
            .build()
        );
      }

      if (transactionType) {
        searchQuery.push(
          builder.push(["journalType", transactionType]).build()
        );
      }
      params.search = searchQuery.join(builder.AND);

      this.loading.download = true;

      accountingService
        .getJournalTransactionDetailDownload(params)
        .then(res => {
          if (res) {
            const { toDownload } = useBlob();
            toDownload(res, "journal_transactions_group_.xlsx");
          }
        })
        .finally(() => (this.loading.download = false));
    });
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
}
