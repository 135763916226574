var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_journal_transactions_detail_report") } },
    [
      _c(
        "a-form",
        _vm._b(
          {
            attrs: { "label-align": "left", form: _vm.form },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.handleDownload.apply(null, arguments)
              }
            }
          },
          "a-form",
          _vm.formItemLayout,
          false
        ),
        [
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.transactionType.label) } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: _vm.formRules.transactionType.decorator,
                      expression: "formRules.transactionType.decorator"
                    }
                  ],
                  attrs: {
                    placeholder: _vm.$t(
                      _vm.formRules.transactionType.placeholder
                    ),
                    "show-search": "",
                    "option-filter-prop": "children",
                    "filter-option": _vm.filterOption,
                    loading: _vm.loading.transactionType,
                    "allow-clear": ""
                  }
                },
                _vm._l(_vm.dtTransactionType, function(data) {
                  return _c(
                    "a-select-option",
                    { key: data.value, attrs: { value: data.value } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" " + _vm._s(data.value) + " ")
                          ]),
                          _vm._v(" " + _vm._s(data.value) + " ")
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.periodFrom.label) } },
            [
              _c("a-date-picker", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.periodFrom.decorator,
                    expression: "formRules.periodFrom.decorator"
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  placeholder: _vm.$t(_vm.formRules.periodFrom.placeholder),
                  format: _vm.DEFAULT_DATE_FORMAT
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.periodTo.label) } },
            [
              _c("a-date-picker", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.periodTo.decorator,
                    expression: "formRules.periodTo.decorator"
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  placeholder: _vm.$t(_vm.formRules.periodTo.placeholder),
                  format: _vm.DEFAULT_DATE_FORMAT
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { "wrapper-col": { offset: 4 } } },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading.download,
                    "html-type": "submit"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }